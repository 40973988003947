/* Sidebar Styling */
.sidebar {
    background-color: #001529;
}


/* Content Styling */
.content {
    padding: 40px;
    background-color: #f0f2f5;
}

.dashboard-title {
    color: #1890ff;
    text-align: center;
    margin-bottom: 24px;
    font-size: 28px;
    font-weight: bold;
}

/* Dashboard Container */
.dashboard-container {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    justify-content: center;
}

/* Card Styling */
.dashboard-card {
    flex: 1 1 calc(25% - 24px);
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    text-align: center;
    max-width: 300px;
}

.nested-card {
    margin-top: 8px;
    border: 1px solid #d9d9d9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-description {
    color: #8c8c8c;
    font-size: 14px;
}