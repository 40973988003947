/* Sidebar Styling */
.sidebar {
    background-color: #001529;
}

/* Content Styling */
.content {
    padding: 40px;
    background-color: #f0f2f5;
}

/* Location Selector Styling */
.location-selector-card {
    max-width: 500px;
    margin: 0 auto 20px;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
    background-color: #ffffff;
}

/* Custom Dropdown Style for Location Selector */
.location-dropdown .ant-select-item {
    font-size: 14px;
}

.location-selector-card .ant-select {
    border-radius: 6px;
}

.location-selector-card .ant-select-selector {
    border-radius: 6px !important;
}

.location-selector-card .ant-select-focused .ant-select-selector {
    border-color: #1890ff !important;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

/* Dashboard Container */
.dashboard-container {
    display: flex;
    gap: 24px;
    justify-content: center;
    margin-bottom: 30px;
}

/* Card Styling */
.dashboard-card {
    flex: 1 1 calc(50% - 24px);
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    text-align: center;
    max-width: 400px;
}

.card-description {
    color: #8c8c8c;
    font-size: 14px;
}

/* Insights Section */
.insights-section {
    margin-top: 30px;
}

/* Insight Card Styling */
.insight-card {
    background-color: #f9f9f9;
    border-radius: 10px;
    border: 1px solid #e0e0e0;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    transition: all 0.3s;
}

.insight-card:hover {
    border-color: #1890ff;
    box-shadow: 0 6px 16px rgba(24, 144, 255, 0.2);
}

.insight-card .ant-typography {
    font-size: 16px;
    color: #333;
}

.location-dropdown {
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.location-dropdown .ant-select-item-option {
    font-size: 16px;
    padding: 10px 15px;
}

.location-dropdown .ant-select-item-option-selected {
    background-color: #1890ff !important;
    color: white !important;
}
.location-dropdown .ant-select-item-option:hover {
    background-color: #e6f7ff;
    color: #1890ff;
}