 /* General Styles for the Layout */
 .layout {
     min-height: 100vh;
 }

 .sidebar {
     background-color: #001529;
     /* Darker color for sidebar */
     color: #ffffff;
 }

 .content {
     padding: 50px;
     background-color: #f0f2f5;
     /* Light background for content area */
 }

 /* Dashboard Title */
 .dashboard-title {
     color: #1890ff;
     /* Light blue for primary branding */
     font-size: 28px;
     font-weight: bold;
     margin-bottom: 20px;
 }

 /* Dashboard Container */
 .dashboard-container {
     display: flex;
     flex-wrap: wrap;
     gap: 16px;
     margin-bottom: 30px;
 }

 /* Dashboard Cards */
 .dashboard-card {
     width: 100%;
     max-width: 300px;
     border: 1px solid #e6e6e6;
     border-radius: 8px;
     background: #ffffff;
     box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
 }

 .dashboard-card .ant-statistic-title {
     font-weight: bold;
 }

 .dashboard-card .nested-card {
     margin-top: 10px;
     border-left: 4px solid #1890ff;
     padding-left: 10px;
 }

 /* Chart Container */
 .chart-container {
     margin-top: 30px;
     padding: 20px;
     background: #ffffff;
     border-radius: 8px;
     box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
 }

 /* Insights Container */
 .insights-container {
     margin-top: 40px;
 }

 .insights-title {
     color: #1890ff;
     font-size: 24px;
     margin-bottom: 20px;
 }

 .insight-card {
     border: none;
     border-radius: 8px;
     background: #fff4e6;
     padding: 15px;
     box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
 }

 .card-description {
     font-size: 14px;
     color: #808080;
     text-align: center;
 }

 /* Styling for Menu Items */
 .ant-menu-item {
     font-size: 16px;
     color: #ffffff;
     transition: background 0.3s;
 }

 .ant-menu-item:hover {
     background: rgba(24, 144, 255, 0.6);
 }

 .ant-menu-item-selected {
     background: #1890ff !important;
     color: #ffffff;
 }

 /* Ant Design Alerts for Insights */
 .insight-alert {
     margin-bottom: 10px;
     border-radius: 8px;
     padding: 10px;
     font-size: 14px;
     font-weight: bold;
 }

 /* Ant Design Card Adjustments */
 .ant-card {
     border-radius: 8px;
     overflow: hidden;
 }

 .ant-card-bordered {
     border: none;
 }

 .ant-card-head-title {
     font-weight: bold;
 }

 .ant-card-body {
     padding: 20px;
 }

 .ant-card-small {
     padding: 10px;
     font-size: 14px;
 }

 /* Responsive Design */
 @media (max-width: 768px) {
     .dashboard-container {
         display: block;
     }

     .dashboard-card,
     .chart-container {
         width: 100%;
         max-width: none;
     }
 }
 .content {
     padding: 20px;
 }

 .dashboard-container {
     display: flex;
     flex-wrap: wrap;
     gap: 16px;
 }

 .dashboard-card {
     width: 30%;
     min-width: 280px;
 }

 .insights-table {
     margin-top: 20px;
 }

 /* ManagerDashboard.css */



 .average-time-cards {
     display: flex;
     flex-wrap: wrap;
     gap: 16px;
     justify-content: space-around;
 }

 .dashboard-card {
     width: 30%;
     min-width: 280px;
 }

 .insights-title {
    width: 100%;
     margin-top: 20px;
 }

 .insights-table {
    width: 100%;
     margin-top: 10px;
 }