/* Logo Styling */
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.logo-text {
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  margin-left: 10px;
}

/* Main Menu */
.main-menu {
  background: linear-gradient(135deg, #001529, #004b87);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Menu Item Styling */
.ant-menu-item {
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  font-size: 15px;
  font-weight: bold;
  color: #d9d9d9;
}

.ant-menu-item.collapsed-icon-only {
  justify-content: center;
  /* Center the icon when sidebar is collapsed */
  padding-left: 0 !important;
  /* Remove padding so icon is properly centered */
}

.ant-menu-item:hover {
  background-color: #1890ff !important;
  color: #ffffff !important;
}

/* Icon Styling */
.ant-menu-item .anticon {
  font-size: 24px;
  color: #40a9ff;
}

/* Hide Text When Collapsed */
.menu-item-text-hidden {
  display: none;
}

.menu-item-text {
  margin-left: 10px;
  color: inherit;
}

/* Selected Menu Item */
.ant-menu-item-selected {
  background-color: #1d39c4 !important;
  color: #ffffff !important;
  border-left: 6px solid #ffffff;
  font-weight: bold;
}

/* Remove List Dots from Menu Links */
.ant-menu-item a {
  text-decoration: none;
  list-style-type: none;
  color: inherit;
}

.ant-menu-item a:hover {
  color: #ffffff;
}

/* Sidebar Toggle Button Styling */
.logo .ant-btn-primary {
  background-color: transparent;
  border: none;
  color: #ffffff;
}

/* Content Styling */
.ant-layout-content {
  background: #f0f2f5;
  padding: 24px;
  margin: 0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}